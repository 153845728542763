
import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useHistory} from 'react-router-dom';

import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import "@near-wallet-selector/modal-ui/styles.css"
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const logout = async (eve) => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      await myNearWallet.signOut();
      await meteorWallet.signOut();
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      localStorage.clear();
      history.push('/Plan');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
    top="40px"
    title="/Plan"
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >

    <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
    <VuiBox
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px"
    sx={({ palette: { secondary } }) => ({
      backgroundColor: secondary.focus,
    })}
    >

    <SignUpForm />
    </VuiBox>
    </GradientBorder>

    </CoverLayout>
    );
}

export default SignIn;
