import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from "prop-types";
import Avatar from '@mui/material/Avatar';

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Vision UI Dashboard PRO React page layout routes
import { useHistory } from 'react-router-dom';

import pageRoutes from "page.routes";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";
import tgtImage from 'assets/images/tgt.svg';


function CoverLayout({
  color,
  header,
  title,
  description,
  motto,
  premotto,
  image,
  top,
  cardContent,
  children,
}) {
  const { gradients } = colors;
    const history = useHistory();

  return (
    <PageLayout
    background={tripleLinearGradient(
      '#0F123B',
      '#4D1452',
      '#3B0F39',
      '166'
      )}
    >
    <VuiBox
    height="100%"
    width="50vw"
    display={{ xs: "none", md: "block" }}
    position="absolute"
    top={0}
    left={0}
    sx={({ breakpoints }) => ({
      overflow: "hidden",
      [breakpoints.down("xl")]: {
        mr: "100px",
      },
      [breakpoints.down("lg")]: {
        display: "none",
      },
    })}
    zIndex={0}
    >
    <VuiBox
    height="100%"
    sx={{
      backgroundImage: `url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    >
    <VuiTypography
    textAlign={cardContent ? "center" : "start"}
    variant="subtitle1"
    fontWeight="medium"
    color="white"
    mb="10px"
    sx={{ mb: 1, letterSpacing: "8px" }}
    >
    {premotto}
    </VuiTypography>
    <VuiTypography
    textAlign={cardContent ? "center" : "start"}
    variant="h2"
    fontWeight="bold"
    color="logo"
    mb="10px"
    textGradient
    sx={{ letterSpacing: "8px" }}
    >
    {motto}
    </VuiTypography>
    </VuiBox>
    </VuiBox>
    <VuiBox
    sx={{
      display: "flex",
      justifyContent: "center",
      position: "relative",
      alignItems: "center",
      maxWidth: "1044px",
      minHeight: "75vh",
      margin: "0 auto",
    }}
    >
    <VuiBox
    mt="40px"
    ml="auto !important"
    sx={({ breakpoints }) => ({
      [breakpoints.down("xl")]: {
        mr: cardContent ? "50px" : "100px",
      },
      [breakpoints.down("lg")]: {
        mr: "auto",
        ml: "auto !important",
      },
      [breakpoints.down("md")]: {
        maxWidth: "90%",
        pr: "7px",
        pl: "10px !important",
      },
    })}
    >
    <VuiBox pt={3} px={3} mx="auto !important" maxWidth={cardContent ? "400px" : "350px"}>
    {!header ? (
      <>
      <VuiBox mb="35px">
      {!title ? (
        null
          ):(
<ArrowBackIcon
          style={{ color: "white", fontSize: '5rem', marginRight: '10px', cursor: 'pointer' }}
          onClick={() => {
            if (title) {
              history.push(title);
            }
          }}
        />
                  )
      }
      <VuiBox mb="35px" display="flex" justifyContent="center">
      <img style={{width:"150px"}} src={tgtImage} alt="Logo" />
      </VuiBox>
      <VuiTypography
      textAlign={cardContent ? "center !important" : "start !important"}
      mx="auto"
      sx={({ typography: { size }, functions: { pxToRem } }) => ({
        fontWeight: "regular",
        fontSize: size.sm,
      })}
      color="white"
      >
      COMMUNITY POWERED COMMERCE    </VuiTypography>
      </VuiBox>
      </>
      ) : (
      header
      )}
      </VuiBox>
      <VuiBox
      px={3}
      mb="50px"
      mx="auto"
      ml="auto !important"
      sx={({ breakpoints }) => ({
        mt: cardContent ? "60px" : { top },
        maxWidth: cardContent ? "450px" : "350px",
        [breakpoints.down("xl")]: {
          mr: cardContent ? "0px" : "100px",
        },
        [breakpoints.only("lg")]: {
          mr: "auto",
          ml: "auto !important",
        },
        [breakpoints.down("lg")]: {
          mr: "auto",
          ml: "auto !important",
        },
        [breakpoints.down("md")]: {
          mr: cardContent ? "auto !important" : "unset",
          pr: "7px",
          pl: cardContent ? "0px !important" : "10px !important",
        },
      })}
      >
      {children}
      <VuiTypography
      style={{marginTop:"50px",color:"#BDC9E2",fontSize:"10px"}}
      textAlign={cardContent ? "center !important" : "start !important"}
      sx={({ typography: { size }, functions: { pxToRem } }) => ({
        fontWeight: "regular",
        fontSize: size.sm,
      })}
      color="white"
      >Powered by <a href="https://tgt.wtf" target="_blank" rel="noopener noreferrer" style={{ color: "#F5367B", textDecoration: "underline", marginLeft: "4px" }}>together</a> A visionary product of <a href="http://www.fireworks.digital/" target="_blank" rel="noopener noreferrer" style={{ color: "#F5367B", textDecoration: "underline", marginLeft: "4px" }}> Fireworks Digital </a> <br/> &copy; 2024 Mobility Asia Sdn. Bhd. (Registration No: 1257623-M)</VuiTypography>
      </VuiBox>

      </VuiBox>
      </VuiBox>
      </PageLayout>
      );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
