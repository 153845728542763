import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    address1,
    address2,
    city,
    states,
    zip,
  },
} = checkout;

export default {
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [states.name]: "",
  [zip.name]: "",
};
