import { useState,useEffect  } from "react";
import { useHistory} from 'react-router-dom';
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import "@near-wallet-selector/modal-ui/styles.css"
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import VuiSnackbar from "components/VuiSnackbar";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import unknown from 'assets/images/unknown.png';
import lucky from 'assets/images/lucky.png';
import rebirth from 'assets/images/rebirth.png';
import voucher2 from 'assets/images/RM10_Onsemiro.png';
import voucher1 from 'assets/images/RM15_Gangnam_Pizza.png';
import voucher3 from 'assets/images/v3.png';
import voucher4 from 'assets/images/v4.png';
import voucher5 from 'assets/images/v5.png';
import voucher6 from 'assets/images/v6.png';
import CircularProgress from '@mui/material/CircularProgress';

import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
function refreshPage() {
  window.location.reload();
}
function Teams() {
  const [loading, setLoading] = useState(true);
  const [successSB, setSuccessSB] = useState(false);
  const [waitSB, setWaitSB] = useState(false);
  const [savedType, setSavedType] = useState("");
  const [savedOrderID, setSavedOrderID] = useState("");
  const [savedTransactionID, setSavedTransactionID] = useState("");
  const [hashto, setHashto] = useState("");
  const [savedHash, setSavedHash] = useState("");
  const [hashtransfer, setHashtransfer] = useState("");
  const [savedtgtWallet, setsavedtgtWallet] = useState("");
  const [savedtokenid, settokenid] = useState("");
  const [savedDate, setDate] = useState("");
  const [savedStatus, setStatus] = useState("");
  const [getPack, setPack] = useState("");
  const [getPacka, setPacka] = useState("");
  const [getmint, setmint] = useState("MINTING...");
  const history = useHistory();
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

  const token = localStorage.getItem('token');

  if(!token){
    history.push('/Plan');
  }
  
  const cardStyle = {
    borderRadius: '25px',
    color: 'white',
    padding: '20px',
    position: 'relative',
    border: '3px solid white',

  };
  const cardStyles = {
    borderRadius: '25px',
    color: 'white',
    padding: '20px',
    position: 'relative',
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows:true
  };

  async function fetchData() {
    try {
      const response = await fetch('/api/getCustDetail', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if(data.package_detail!=null){
        setPack(data.package_detail);
      }
      if(data.paid!=1){
        history.push('/Plan');
      }
      setSavedType(data.walletype);
      setSavedHash(data.hash);
      setSavedOrderID(data.orderid);
      setSavedTransactionID(data.transaction);
      setHashto(data.hashto);
      setHashtransfer(data.hashtransfer);
      setsavedtgtWallet(data.tgtwallet);
      settokenid(data.tokenid);
      setDate(data.date);
      setStatus(data.status);
      setPacka(data.package_detail.id);
      if (data.hashto || (data.walletype !== "istgt" && data.walletype !== "")) {
        setSuccessSB(true);
      }
      setLoading(false);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  async function fetchMarket() {
    if(savedtokenid.includes("506166")){
      const responses = await fetch(`/checkNFTs?code=${savedtokenid}`);
      if (!responses.ok) {
        setWaitSB(true);
      } else {
        window.open("https://paras.id/token/x.paras.near::506166/" + savedtokenid, '_blank').focus();
      }
    }else{
      const responses = await fetch(`/checkNFT?code=${savedtokenid}`);
      if (!responses.ok) {
        setWaitSB(true);
      } else {
        window.open("https://paras.id/token/x.paras.near::506165/" + savedtokenid, '_blank').focus();
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const closeSuccessSB = () => {
    setSuccessSB(false);
  };
  const closeWaitSB = () => {
    setWaitSB(false);
  };

  const renderSuccessSB = (
    <VuiSnackbar
    color="success"
    icon="check"
    title="Togethër"
    content={savedType === "istgt" ? `You have transferred your NFT to ${hashto}` : `You have transferred your NFT to ${savedtgtWallet}`}
    open={successSB}
    onClose={closeSuccessSB}
    close={closeSuccessSB}
    />
    );

  const renderWaitSB = (
    <VuiSnackbar
    color="info"
    icon="check"
    title="Togethër"
    content="It will take a few minutes for your NFT to appear in marketplace. If you cannot see it now, kidnly check back in a few minutes."
    open={waitSB}
    onClose={closeWaitSB}
    close={closeWaitSB}
    />
    );

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`/getMintStatus?wallet=${savedtgtWallet}`);
      const jsonData = await response.json();

      if (!jsonData.data.status || jsonData.data.status === "minting") {
        const mintDatetime = new Date(jsonData.data.mint_datetime);
        const currentTime = new Date();

        const timeDifference = currentTime - mintDatetime;
        const timeDifferenceInMinutes = timeDifference / (1000 * 60);

        if (timeDifferenceInMinutes > 2) {
          setmint("fail");
        } else {
          setmint("Minting NFT. Please check back in a few minutes");
        }
      } else if (jsonData.data.status === "success") {
        setSavedHash(jsonData.data.hash);
        settokenid(jsonData.data.tokenid);
        clearInterval(intervalId); 
      } else if (jsonData.data.status === "error") {
        setmint(jsonData.data.mint_error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  let intervalId;

  if (savedtgtWallet) {
    fetchData();
    intervalId = setInterval(fetchData, 3000); 
  }

  return () => clearInterval(intervalId);
}, [savedtgtWallet]);



    const checkwallet = async (eve) => {
      setmint("Minting");
      const responses = await fetch('/checkStatus', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!responses.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await responses.json();
    };

  const handleClick = (eve) => {
    history.push('/send');
  };
  const handleClicks = (eve) => {
    fetchMarket();
  };
  const handleClickss = (eve) => {
    window.open("https://nearblocks.io/txns/"+hashtransfer, '_blank').focus();
  };
  const handleClicksswallet = (eve) => {
    fetchMarket();
  };
  const logout = async (eve) => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      await myNearWallet.signOut();
      await meteorWallet.signOut();
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      localStorage.clear();
      history.push('/Plan');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <CoverLayout
    top="40px"
    title=""
    color="white"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    {renderSuccessSB}
    <Grid style={{ marginTop:"-10px"}} item xs={12} lg={5} xl={5}>
    <Card>
    <div style={cardStyles}>
    {getPack != "" ? (
      <>
      <p style={{ fontSize:'13px',marginBottom:'10px'}}>Package Name: <strong>{getPack.name}</strong></p>
      <p style={{ fontSize:'13px',marginBottom:'10px'}}>Price: <strong>{getPack.price}</strong></p>
      </>
      ) : (
      null
      )
    }

    <p style={{ fontSize:'13px'}}>Transaction ID: <strong>{savedTransactionID}</strong></p>
    <div style={{ color: 'white', paddingTop: '20px' }}>
    {savedTransactionID != "" ? (
      <VuiButton variant="contained" color="success">Payment Success</VuiButton>

    ):(
      <VuiButton variant="contained" color="warning"> {savedStatus} </VuiButton>

    )}
    </div>
    </div>
    </Card>
    </Grid>
    <Grid style={{ marginTop:"50px"}} item xs={12} lg={5} xl={5}>
    {getPack.id === 1 || getPack.id === 3 ? (
    <Card>
    <div style={cardStyles}>
    <p style={{ fontSize:'12px',marginBottom:'10px'}}>Pre-sales completed. Please await shipment.</p>
    <p style={{fontSize:'16px',marginBottom:'10px'}}>Order Details</p>
    {getPack != "" ? (
      <>
      <img style={{maxWidth:"100%",height:"auto"}} src="https://app.tgt.wtf/loyalty/uploads/package/rebirths.png" alt="package Image" />
      <p style={{ fontSize:'13px',marginBottom:'10px'}}>Product Name: <strong>The Cheek Canvas: Rebirth Blusher</strong></p>
      </>
      ) : (
      null
      )
    }
    <p style={{ fontSize:'13px',marginBottom:'10px'}}>Order No: <strong>{savedOrderID}</strong></p>
    <p style={{ fontSize:'13px',marginBottom:'10px'}}>Order Date: <strong>{savedDate}</strong></p>
    <div style={{ color: 'white', paddingTop: '20px' }}>
      <VuiButton variant="contained" color="warning" style={{color:"black"}}>{savedStatus}</VuiButton>
    </div>
    </div>
    </Card>
  ):(
    <Card>
    <div style={cardStyles}>
    <p style={{ fontSize:'12px',marginBottom:'10px'}}>Pre-sales completed. Please await shipment.</p>
    <p style={{fontSize:'16px',marginBottom:'10px'}}>Order Details</p>
    {getPack != "" ? (
      <>
      <img style={{maxWidth:"100%",height:"auto"}} src="https://app.tgt.wtf/loyalty/uploads/package/lucky.png" alt="package Image" />
      <p style={{ fontSize:'13px',marginBottom:'10px'}}>Product Name: <strong>The Cheek Canvas: Lucky Blusher</strong></p>
      </>
      ) : (
      null
      )
    }
    <p style={{ fontSize:'13px',marginBottom:'10px'}}>Order No: <strong>{savedOrderID}</strong></p>
    <p style={{ fontSize:'13px',marginBottom:'10px'}}>Order Date: <strong>{savedDate}</strong></p>
    <div style={{ color: 'white', paddingTop: '20px' }}>
      <VuiButton variant="contained" color="warning" style={{color:"black"}}>{savedStatus}</VuiButton>
    </div>
    </div>
    </Card>
  )
}
    </Grid>
    <VuiBox>
    {getPacka === 1 ||  getPacka === 2 ? (
<>
    <Grid item xs={12} lg={5} xl={5} mt={2}>
    <Card style={{ marginTop:"40px"}}>
    <VuiTypography display="inline" style={{marginBottom:"10px"}} variant="h5" color="white" mb={{ xs: 5, xl: 5, xxl: 5 }}>Your NFT</VuiTypography>
    {getPacka === 2 ? (
      <img style={{maxWidth:"100%",height:"auto"}} src={lucky} alt="Logo" />
    ):(
      <img style={{maxWidth:"100%",height:"auto"}} src={rebirth} alt="Logo" />
    )
  }
    <VuiBox>

    {savedType === "istgt" ? (
      <>
      {savedHash === "" ? (
        <>
{getmint === "fail" ? (
  <>
    <p style={{ textAlign: "center", color: "white", fontSize: '12px', marginBottom: '10px' }}>Added to your Togethër wallet {savedtgtWallet}</p>
    <VuiButton style={{ background: "#4536f5" }} variant="contained" color="error" fullWidth onClick={checkwallet}>Retry</VuiButton>
  </>
) : (
  <>
    <p style={{ textAlign: "center", color: "white", fontSize: '12px', marginBottom: '10px' }}>Added to your Togethër wallet {savedtgtWallet}</p>
    <VuiButton style={{ background: "#4536f5" }} variant="contained" color="error" fullWidth> <CircularProgress style={{ marginLeft:"10px", width: '20px', height: '20px', color: '#FFFFFF' }} />{getmint}</VuiButton>
  </>
)}


        </>
      ):(
          <>
      <VuiBox mt={2}>
      {hashto == "" ? (
      <p style={{textAlign:"center",color:"white", fontSize:'12px',marginBottom:'10px'}}>Added to your Togethër wallet {savedtgtWallet}</p>
 ) : (
 null
 )}
      {hashto != "" ? (
        <VuiButton style={{background:"#4536f5"}} variant="contained" color="error" fullWidth> SENT {hashto} </VuiButton>
        ) : (
        <VuiButton style={{background:"#F5367B"}} variant="contained" color="error" fullWidth onClick={handleClick}> SEND TO WALLET </VuiButton>
        )}
        </VuiBox>

        <VuiBox mt={2}>
        {hashto != "" ? (
          <VuiButton style={{background:"transparent", border:"white 1px solid"}} onClick={handleClicks} variant="contained" color="secondary" fullWidth> VIEW IN MARKETPLACE</VuiButton>
          ) : (
          <VuiButton style={{background:"transparent", border:"white 1px solid"}} onClick={handleClicks} variant="contained" color="secondary" fullWidth> VIEW IN MARKETPLACE </VuiButton>
          )}
          </VuiBox>
          </>
        )}
          </>
          ) : (
          <>
          <VuiBox mt={2}>
          <p style={{textAlign:"center",color:"white", fontSize:'12px',marginBottom:'10px'}}>Added to your wallet {savedtgtWallet}</p>

          <VuiButton style={{marginTop:"10px"}} variant="contained" color="secondary" onClick={handleClicksswallet} fullWidth> VIEW IN MARKETPLACE </VuiButton>
          </VuiBox>
          </>
          )}
          </VuiBox>
          </Card>
          </Grid>
          </>
        ):(
          null
        )}
          {getPacka === 1 ||  getPacka === 2 ? (
            <>
          <style>
          {`
          .slick-dots li button:before {
            margin-top:15px;
            font-size: 15px;
            color: white;
            opacity:1;

          }
          .slick-dots li.slick-active button:before {
            color: #f5367b;
          }
          `}
          </style>
          <Slider {...settings} style={{marginTop:"40px"}}>
          <Card style={{ background: 'transparent', padding: '0px' }}>
          <div style={cardStyles}>
          <VuiTypography display="inline" style={{marginBottom:"10px"}} variant="h5" color="white" mb={{ xs: 5, xl: 5, xxl: 5 }}>Voucher</VuiTypography>
          <img style={{maxWidth:"100%",height:"auto",marginTop:"10px"}} src={voucher1} alt="package Image" />
          <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 15 discount voucher at Gangnam Pizza</strong></p>
          <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
          <div style={{ color: 'white', paddingTop: '20px' }}>
          </div>
          </div>
          </Card>

            <Card style={{ background: 'transparent', padding: '0px' }}>
            <div style={cardStyles}>
            <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
            <img style={{maxWidth:"100%",height:"auto"}} src={voucher2} alt="package Image" />
            <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 10 discount voucher at Onsemiro Restaurant</strong></p>
            <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
            <div style={{ color: 'white', paddingTop: '20px' }}>
            </div>
            </div>
            </Card>

            <Card style={{ background: 'transparent', padding: '0px' }}>
            <div style={cardStyles}>
            <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
            <img style={{maxWidth:"100%",height:"auto"}} src={voucher3} alt="package Image" />
            <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 39 discount Wyndham Grand Bangsar Ramadhan Buffet</strong></p>
            <p style={{ fontSize:'13px',marginBottom:'10px'}}>
            For reservation, please contact
            <br />Tel: +603 - 2298 1888 (ext. 3400, 3404, 3405)
            <br />WhatsApp/Call : +6016 - 336 6792 | +6016 336 0261
            <br />Email: fb1@wyndhamgrandbangsarkl.com
            </p>
            <div style={{ color: 'white', paddingTop: '20px' }}>
            </div>
            </div>
            </Card>

            <Card style={{ background: 'transparent', padding: '0px' }}>
            <div style={cardStyles}>
            <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
            <img style={{maxWidth:"100%",height:"auto"}} src={voucher4} alt="package Image" />
            <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 100 discount Wyndham Grand Bangsar Staycation Promo</strong></p>
            <p style={{ fontSize:'13px',marginBottom:'10px'}}>For reservation, please contact
            <br />Tel: +603 - 2298 1888 (ext. 3400, 3404, 3405)
            <br />WhatsApp/Call : +6016 - 336 6792 | +6016 336 0261
            <br />Email: fb1@wyndhamgrandbangsarkl.com
            </p>
            <div style={{ color: 'white', paddingTop: '20px' }}>
            </div>
            </div>
            </Card>

            <Card style={{ background: 'transparent', padding: '0px' }}>
            <div style={cardStyles}>
            <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
            <img style={{maxWidth:"100%",height:"auto"}} src={voucher5} alt="package Image" />
            <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>10% Discount at Feeka Coffee Roasters (All Outlets)</strong></p>
            <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
            <div style={{ color: 'white', paddingTop: '20px' }}>
            </div>
            </div>
            </Card>

            <Card style={{ background: 'transparent', padding: '0px' }}>
            <div style={cardStyles}>
            <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
            <img style={{maxWidth:"100%",height:"auto"}} src={voucher6} alt="package Image" />
            <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>10% Discount at Pizza Mansion (All Outlets)</strong></p>
            <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
            <div style={{ color: 'white', paddingTop: '20px' }}>
            </div>
            </div>
            </Card>

          </Slider>
          </>
        ):(
          null
        )}
          {renderWaitSB}
          <VuiButton style={{border:"2px solid #f5367b",color:"#f5367b",background:"transparent",marginTop:"100px"}} variant="contained" color="error" fullWidth onClick={logout}>Log Out</VuiButton>
          </VuiBox>
          </CoverLayout>
          );
}

export default Teams;
