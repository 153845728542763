import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PageLayout from "examples/LayoutContainers/PageLayout";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory, useLocation } from 'react-router-dom';

const CheckoutButton = () => {
  const location = useLocation();
  const { token, email } = location.state || {};
  const history = useHistory();
  const key = localStorage.getItem('item');

  const [stripe, setStripe] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!location.state) {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        fetch('/api/getCustDetail', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`,
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.paid !== "1") {
            history.push('/Plan');
          } else {
            history.push('/Welcome');
          }
        })
        .catch(error => {
          console.error('Error fetching customer details:', error);
          history.push('/Plan'); // Redirect to '/Plan' in case of an error
        });
      } else {
        history.push('/Plan'); // If token is not present in localStorage, redirect to '/Plan'
      }
    }
  }, [location.state, history]);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripe = await loadStripe('pk_live_slx6i1FJQlPXEQPPfLD4WAhg00Msvbti4l');
        setStripe(stripe);
      } catch (error) {
        setError(error.message);
      }
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    if (!stripe && token) return;

    const handleClick = async () => {
      if (email) {
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{ price: key, quantity: 1 }],
          mode: 'payment',
          successUrl: `${window.location.origin}/payment-success?session_id={CHECKOUT_SESSION_ID}&token=${token}`,
          cancelUrl: `${window.location.origin}/More_info`,
          customerEmail: email,
        });
        if (error) {
          setError(error.message);
        }
      } else {
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{ price: key, quantity: 1 }],
          mode: 'payment',
          successUrl: `${window.location.origin}/payment-success?session_id={CHECKOUT_SESSION_ID}&token=${token}`,
          cancelUrl: `${window.location.origin}/More_info`,
        });
        if (error) {
          setError(error.message);
        }
      }
    };
    if(token){
    handleClick();
    }
  }, [stripe, token, email, key]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, #1a123f 30%, #461248 70%)',
      }}>
        <CircularProgress style={{ width: '100px', height: '100px', color: '#FFFFFF' }} />
      </div>
    );
  }

  return (
    <PageLayout>
      {/* Content */}
    </PageLayout>
  );
};

export default CheckoutButton;
