import { useState,useEffect } from "react";
import { useHistory} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import success from 'assets/images/success.png';
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import NFT from 'assets/images/Group 86872@3x.png'
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
function Send() {
  const [loading, setLoading] = useState(true);
  const [hashto, setHashto] = useState("");
  const [savedTokenid, setSavedTokenid] = useState("");
  const [hashtransfer, setHashtransfer] = useState("");

  const token = localStorage.getItem('token');

  const history = useHistory();

  const handleClick = () => {
    history.push('/welcome');
  };

  async function fetchData() {
    try {
      const response = await fetch('/api/getCustDetail', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      setHashto(data.hashto);
      setHashtransfer(data.hashtransfer);
      setSavedTokenid(data.tokenid);
      setLoading(false);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleClickss = (eve) => {
    window.open("https://nearblocks.io/txns/"+hashtransfer, '_blank').focus();
  };
  return (
    <CoverLayout
    top="50px"
    title=""
    color="white"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >
    <VuiBox>
    <Grid item xs={12} lg={5} xl={5}>
    <Card>
    <div style={{marginTop:"15px",marginBottom:"15px",padding:"10px",display:"flex",flexDirection:"column",alignItems:"center"}}>
    <VuiTypography display="inline" variant="body2" verticalAlign="middle" color="white">({hashto})</VuiTypography>
    </div>
    <img src={success} alt="Logo" />

    <div style={{marginTop:"22px",padding:"10px",display:"flex",flexDirection:"column",alignItems:"start"}}>
    <VuiTypography style={{marginBottom:"20px"}} display="inline" variant="body2" verticalAlign="middle" color="white">Transaction ID</VuiTypography>
    <VuiInput
    disabled
    value={hashtransfer}
    >
    </VuiInput>
    <VuiButton style={{marginTop:"20px",background:"transparent", border:"white 1px solid"}} onClick={handleClickss} variant="contained" color="secondary" fullWidth> VIEW ON EXPLORER</VuiButton>

    </div>
    <div style={{ color: 'white',textAlign:"left"}}>
    <VuiButton
    style={{background:"#F5367B",marginTop:"20px"}}
    color="error"
    fullWidth
    onClick={handleClick}
    >Finish
    </VuiButton>
    </div>
    </Card>
    </Grid>
    </VuiBox>
    </CoverLayout>
    );
}

export default Send;
