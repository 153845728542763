import Home from "layouts/pages/profile/teams";
import Transfer from "layouts/pages/profile/teams/send";
import TransferSuccess from "layouts/pages/profile/teams/success";
import Plan from "layouts/pages/pricing-page";
import Plan2 from "layouts/pages/pricing-page2";
import ContinueRegister from "layouts/authentication/sign-up/new";
import Register from "layouts/authentication/sign-up/new2";
import Login from "layouts/authentication/sign-up/new3";
import PrivacyPolicy from "layouts/authentication/sign-up/privacy_policy";
import Tnc from "layouts/authentication/sign-up/tnc";
import Stripe from "layouts/authentication/sign-up/stripe";
import StripeSuccess from "layouts/authentication/sign-up/stripe/Success";
import Stripe_test from "layouts/authentication/sign-up/stripe_test";
import StripeSuccess_test from "layouts/authentication/sign-up/stripe_test/Success";
import Game from "layouts/authentication/sign-up/basic";
import Moreinfo from "layouts/pages/users/new-bill";


const routes = [
{
  name: "New",
  key: "new",
  route: "/Continue",
  component: ContinueRegister,
},
{
  name: "New2",
  key: "new2",
  route: "/Register",
  component: Register,
},  
{
  name: "New Billing",
  key: "new-bill",
  route: "/More_info",
  component: Moreinfo,
},
{
  name: "Welcome",
  key: "welcome",
  route: "/welcome",
  component: Home,
},    
{
  name: "Send",
  key: "send",
  route: "/send",
  component: Transfer,
},    
{
  name: "success",
  key: "success",
  route: "/success",
  component: TransferSuccess,
},
{
  name: "Pricing Page",
  key: "pricing-page",
  route: "/Plan",
  component: Plan,
}, 
{
  name: "Pricing Page",
  key: "pricing-page2",
  route: "/PlanNew",
  component: Plan2,
},  
{
  name: "Basic",
  key: "basic",
  route: "/Game",
  component: Game,
},    
{
  name: "New3",
  key: "new3",
  route: "/Login",
  component: Login,
},  
{
  name: "Privacy Policy",
  key: "privacy_policy",
  route: "/privacy_policy",
  component: PrivacyPolicy,
},    
{
  name: "Tnc",
  key: "tnc",
  route: "/tnc",
  component: Tnc,
},
{
  name: "Stripe",
  key: "stripe",
  route: "/payment",
  component: Stripe,
},        
{
  name: "Stripe_Success",
  key: "stripe-success",
  route: "/payment-success",
  component: StripeSuccess,
},
];

export default routes;
