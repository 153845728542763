import { useState ,useEffect} from "react";
import { useHistory} from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from "layouts/pages/users/new-user/components/FormField";
import CircularProgress from '@mui/material/CircularProgress';

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Vision UI Dashboard PRO React components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import lucky from 'assets/images/lucky.png';
import rebirth from 'assets/images/rebirth.png';
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
const sendSchema = Yup.object().shape({
  account: Yup.string()
    .required('Required')
});


function Send() {
	const token = localStorage.getItem('token');

	const [loading, setLoading] = useState(false);
	const [savedHash, setSavedHash] = useState("");
	const [savedTokenid, setSavedTokenid] = useState("");
	const [savedtgtWallet, setsavedtgtWallet] = useState("");
	const history = useHistory();
	const [invliPass, setinvliPass] = useState(false);

	async function fetchData() {
		try {
			const response = await fetch('/api/getCustDetail', {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			setSavedHash(data.hash);
			setSavedTokenid(data.tokenid);
			setsavedtgtWallet(data.tgtwallet);
			setLoading(false);
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
		}
	}
	useEffect(() => {
		fetchData();
	}, []);
	const handleSubmit = async (values, errors, { setSubmitting, setTouched }) => {
		setTouched({
			account: true,
		});
		if (values.account.length === 0 || errors.account) {
			return;
		} else {
			setLoading(true);
			const response = await fetch('/transferNFT', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					account: values.account,
					savedtgtWallet: savedtgtWallet,
					savedTokenid: savedTokenid
				})
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.text();
			if (data == "Wallet not exist") {
				alert('Wallet not exist');
				setLoading(false);
				setinvliPass(true);
			}else{
				const response = await fetch(`/change?hashto=${values.account}&hashtransfer=${data}`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				history.push('/success');
			}

		}
	};

	return (
		<CoverLayout
		top="40px"
		title=""
		color="white"
		image={bgImage}
		premotto={"COMMUNITY POWERED COMMERCE"}
		motto={"Togethër"}
		cardContent
		>
		<VuiBox>
		<Grid item xs={12} lg={5} xl={5}>
		<Card>
		{savedTokenid.includes("506166") ? (
			<img style={{maxWidth:"100%",height:"auto"}} src={lucky} alt="Logo" />
		):(
			<img style={{maxWidth:"100%",height:"auto"}} src={rebirth} alt="Logo" />
		)
	}
		<div style={{ color: 'white',textAlign:"center", padding: '20px' }}>
		<p style={{ fontSize:'15px',color: 'white' }}>Tgt Collection</p>
		<p style={{ fontSize:'12px',color: 'grey' }}>(tgt-token-v2-near #{savedTokenid})</p>
		</div>
		<VuiTypography display="inline" variant="body2" verticalAlign="middle" color="white">Send to</VuiTypography>
		<div style={{ color: 'white',textAlign:"left"}}>
		<Formik
		initialValues={{
			account: ''
		}}
		validationSchema={sendSchema}
		onSubmit={(values, { setSubmitting }) => {
    // Handle form submission
    // Remember to call setSubmitting(false) when done
		}}
		>
		{({ errors, touched, values, setTouched, setFieldValue }) => (
			<Form>
			<FormField
			name="account"
			type="text"
			value={values.account}
			onChange={(e) => {
				const lowercaseValue = e.target.value.toLowerCase();
				setFieldValue('account', lowercaseValue);
				setTouched({ ...touched, account: true });
			}}
  			error={!values.account.includes('.near') && touched.account}
  			success={values.account.includes('.near') && !errors.account}
			/>
			<p style={{ fontSize: "12px", marginBottom: "20px" }}>
			The account ID must be valid such as .near or contain exactly 64 characters.
			</p>
			{values.account.length === 0 || errors.account ? (
				<p style={{ color: "#ff0000", fontSize: "12px", marginBottom: "20px", marginTop: "20px" }}>
				The address you are sending is non-standard mainnet suffix (.mainnet)
				</p>
				) : null}
			<VuiButton
			style={{ background: "#F5367B" }}
			color="error"
			fullWidth
			onClick={() => handleSubmit(values, errors, { setTouched })}
			>
			{loading ? <CircularProgress /> : 'Continue'}
			</VuiButton>
			</Form>
			)}
		</Formik>

		{invliPass ? (
			<p style={{color:"#ff0000",fontSize:"12px",marginBottom:"20px",marginTop:"20px"}}>The wallet address is Invalid. Please try again.</p>
			) : (
			null
			)}
			</div>
			</Card>
			</Grid>
			</VuiBox>
			</CoverLayout>
			);
}

export default Send;
