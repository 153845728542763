    import { useEffect,useState } from "react";
    import Container from "@mui/material/Container";
    import Slider from 'react-slick';
    import 'slick-carousel/slick/slick.css';
    import 'slick-carousel/slick/slick-theme.css';
    import VuiBox from "components/VuiBox";
    import PageLayout from "examples/LayoutContainers/PageLayout";
    import Header from "layouts/pages/pricing-page/components/Header";
    import Footer from "layouts/pages/pricing-page/components/Footer";
    import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
    import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
    import Faq from "layouts/pages/pricing-page/components/Faq";
    import tgtImage from 'assets/images/tgt.svg';
    import CoverLayout from "layouts/authentication/components/CoverLayout";
    import bgImage from "assets/images/background-cover-auth-signup.png";
    import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
    import { useHistory } from 'react-router-dom';
    import VuiTypography from "components/VuiTypography";
    import VuiButton from "components/VuiButton";
  import { setupWalletSelector } from "@near-wallet-selector/core";
  import { setupModal } from "@near-wallet-selector/modal-ui";
  import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
  import "@near-wallet-selector/modal-ui/styles.css"
  import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
    function PricingPage() {
      const [gpackage, setpackage] = useState([]);
      const [getout, setout] = useState(false);
      const history = useHistory();

  const login = async (eve) => {
     history.push('/Register');
  };

  const logout = async (eve) => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      await myNearWallet.signOut();
      await meteorWallet.signOut();
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      localStorage.clear();

      setout(false);
      history.push('/Plan');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows:true
      };

      useEffect(() => {
        fetchPackageData();
      }, []);

    const fetchPackageData = async () => {
        const token = localStorage.getItem('token');
  if(token!=null){
    setout(true);
  }
    const social = localStorage.getItem('social');
  if(social==="near"){
    setout(true);
  }
      try {
        const response = await fetch("/package");
          if (!response.ok) {
            throw new Error("Failed to fetch package data");
          }
          const data = await response.json();

            setpackage(data);

          } catch (error) {
            console.error("Error fetching package data:", error);
          }
        };
        return (
          <CoverLayout
          title=""
          color="white"
          description="Let’s get you activated!"
          image={bgImage}
          premotto={"COMMUNITY POWERED COMMERCE"}
          motto={"Togethër"}
          cardContent
          >

          <VuiBox
          borderRadius="inherit"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
          >
          <style>
          {`

          .slick-dots li button:before {
            margin-top:15px;
            font-size: 15px;
            color: white;
            opacity:1;

          }
          .slick-dots li.slick-active button:before {
            color: #f5367b;
          }
          `}
          </style>
          <Slider {...settings}>
          {gpackage.map((item, index) => (

            <DefaultPricingCard
            badge={{ color: "dark",img:item.img, label: item.name,description:item.description }}
            price={{ currency: "", value: item.price }}
            specifications={item.features.map((feature, index) => ({
              label: feature.title,
              includes: feature.subprice,
            }))}
        action={{
          type: getout ? "external" : "internal",
          route: item.id,
          color: item.stripe_key,
          label: item.gift_id,
          series: item.series,
        }}
        />
        ))}

          </Slider>

         </VuiBox>
{getout === true ? (
  <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "100px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={logout}
  >
    Log Out
  </VuiButton>
) : (
  <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "100px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={login}
  >
    Login
  </VuiButton>
)}


          </CoverLayout>

          );
        }

        export default PricingPage;
