import React, { useEffect, useState } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import PageLayout from "examples/LayoutContainers/PageLayout";
import tnc from 'assets/images/Together Terms and Conditions (User).pdf';
import CoverLayout from "layouts/authentication/components/CoverLayout";

function SignIn() {
  return (
    <div>
      <iframe
        title="PDF Viewer"
        src={tnc}
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        allowFullScreen
      ></iframe>
    </div>
    );
}

export default SignIn;
