import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import "@near-wallet-selector/modal-ui/styles.css"
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import jwt from 'jsonwebtoken';
// @mui material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import AppleSignin from 'react-apple-signin-auth';
import FacebookLogin from "react-facebook-login";

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { SiNear } from "react-icons/si";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SignUpForm from "layouts/authentication/components/Validation";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function refreshPage() {
  window.location.reload();
}
function SignIn() {
  const history = useHistory();
  //google
  const token = localStorage.getItem('token');
  if(token!=null){
      history.push('/Plan');
  }
  function decodeJwtResponse(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  const onSuccess = async (credentialResponse) => {
    const responsePayload = decodeJwtResponse(credentialResponse.credential);

    if (responsePayload.sub) {
         const formData = new FormData();
    formData.append('socialmediatoken', responsePayload.sub);
    formData.append('socialmediatype', 'google');
      try {
        const response = await fetch('/loginsocial', {
        method: 'POST',
        body: formData,
      }); 

        if (!response.ok) {
          if (responsePayload.email) {
           localStorage.setItem('social', "google");
            history.push({
              pathname: '/Continue',
              state: {
                stype: 'google',
                stoken: responsePayload.sub,
                walletname: '',
                walletType: 'istgt',
                semail: responsePayload.email,
                sfname: responsePayload.given_name,
                slname: responsePayload.family_name
              }
            });
          }
        } else {
                     localStorage.setItem('social', "google");

          const responseData = await response.json();
          localStorage.setItem('token', responseData.token);
            if (responseData.custdata.status === "") {
              history.push('/Plan');
            } else {
              history.push('/Welcome');
            }
        }

      } catch (error) {
        console.error('Error logging in:', error);
      }
    }
  };

// Apple login
  const handleSuccess = async (response) => {
    const { authorization} = response;
    const { id_token } = authorization;
    if (id_token) {
      const encodedJWT = id_token;
      const decodedJWT = jwt.decode(encodedJWT);
      const sub_id = decodedJWT.sub;
      const email = decodedJWT.email;
      const firstName = "";
      const lastName ="";

         const formData = new FormData();
    formData.append('socialmediatoken', sub_id);
    formData.append('socialmediatype', 'apple');
      try {
        const response = await fetch('/loginsocial', {
        method: 'POST',
        body: formData,
      }); 

        if (!response.ok) {
           localStorage.setItem('social', "apple");
          history.push({
            pathname: '/Continue',
            state: {
              stype: 'apple',
              stoken: sub_id,
              walletname: '',
              walletType: 'istgt',
              semail: email,
              sfname: firstName,
              slname: lastName
            }
          });
        } else {
                     localStorage.setItem('social', "apple");

          const responseData = await response.json();
          localStorage.setItem('token', responseData.token);
            if (responseData.custdata.status === "") {
              history.push('/Plan');
            } else {
              history.push('/Welcome');
            }
        }

      } catch (error) {
        console.error('Error logging in:', error);
      }
    } else {
      console.error("Incomplete Apple Sign-In response:", response);
    }
  };



  const handleError = (error) => {
    console.error('Error:', error);
  };
/*// Facebook login
  const handleFacebookResponse = async (response) => {
    if (response.status !== "unknown") {
      const { name, email, userID } = response;
      const [firstName, lastName] = name.split(' ');
      if (email) {
        try {
          const response = await fetch('/loginsocial', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ socialmediatoken: userID, socialmediatype: 'facebook' }),
          });

          if (!response.ok) {
            history.push({
              pathname: '/Continue',
              state: {
                stype: 'facebook',
                stoken: userID,
                walletname: '',
                walletType: 'istgt',
                semail: email,
                sfname: firstName,
                slname: lastName
              }
            });
          } else {
            const responseData = await response.json();
            localStorage.setItem('token', responseData.token);
              if (responseData.custdata.data.status === "") {
                history.push('/Plan');
              } else {
                history.push('/Welcome');
              }
          }

        } catch (error) {
          console.error('Error logging in:', error);
        }
      }
    } else {
      console.error("Facebook Sign-In Failed:", response);
    }
  };


  const handleFacebookFailure = (response) => {
    console.error("Facebook Sign-In Failed:", response);
  };*/

//near wallet login
  const [modal, setModal] = useState(null);

  const handleInitializeAndShowModal = async (event) => {
    event.preventDefault();
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      // Show modal
      if (!modal) {
        const walletModal = setupModal(selector, {});
        setModal(walletModal);
        walletModal.show();
      } else {
        modal.show();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  (async () => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      const myNearAccounts = await myNearWallet.getAccounts();
      const meteorAccounts = await meteorWallet.getAccounts();
      let walletname;
      if (meteorAccounts && meteorAccounts.length > 0) {
                 const formData = new FormData();
    formData.append('socialmediatoken',meteorAccounts[0].accountId);
    formData.append('socialmediatype', 'isnear');
      try {
        const response = await fetch('/loginsocial', {
        method: 'POST',
        body: formData,
      }); 

          if (!response.ok) {
            localStorage.setItem('social', "near");
            history.push({
              pathname: '/Continue',
              state: {
                stype: 'isnear',
                stoken: meteorAccounts[0].accountId,
                walletname: meteorAccounts[0].accountId,
                walletType: 'isnear'
              }
            });
          } else {
                        localStorage.setItem('social', "near");

            const responseData = await response.json();
            localStorage.setItem('token', responseData.token);
            if (responseData.custdata.status === "") {
                history.push('/Plan');
              } else {
                history.push('/Welcome');
              }
          }

        } catch (error) {
          console.error('Error logging in:', error);
        }
      }
      if (myNearAccounts && myNearAccounts.length > 0) {
    const formData = new FormData();
    formData.append('socialmediatoken',myNearAccounts[0].accountId);
    formData.append('socialmediatype', 'isnear');
      try {
        const response = await fetch('/loginsocial', {
        method: 'POST',
        body: formData,
      }); 

        if (!response.ok) {
                      localStorage.setItem('social', "near");

          history.push({
            pathname: '/Continue',
            state: {
              stype: 'isnear',
              stoken: myNearAccounts[0].accountId,
              walletname: myNearAccounts[0].accountId,
              walletType: 'isnear'
            }
          });
        } else {
                      localStorage.setItem('social', "near");

          const responseData = await response.json();
          localStorage.setItem('token', responseData.token);
                        if (responseData.custdata.status === "") {
                history.push('/Plan');
              } else {
                history.push('/Welcome');
              }
        }

      } catch (error) {
        console.error('Error logging in:', error);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
})();

return (

  <CoverLayout
  top="40px"
  title="/Plan"
  color="white"
  description="Let’s get you activated!"
  image={bgImage}
  premotto={"COMMUNITY POWERED COMMERCE"}
  motto={"Togethër"}
  cardContent
  >
  <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
  <VuiBox
  component="form"
  role="form"
  borderRadius="inherit"
  p="45px"
  sx={({ palette: { secondary } }) => ({
    backgroundColor: secondary.focus,
  })}
  >
  <VuiTypography
  color="white"
  fontWeight="bold"
  textAlign="center"
  mb="24px"
  sx={({ typography: { size } }) => ({
    fontSize: size.lg,
  })}
  >
  Continue with
  </VuiTypography>
  <Stack mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
 {/* <GradientBorder borderRadius="xl">
  <a href="#">
  <IconButton
  onClick={async (event) => {
    event.preventDefault();
    const facebookButtons = document.getElementsByClassName("kep-login-facebook");
    if (facebookButtons.length > 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
      if (facebookButtons[0]) {
        facebookButtons[0].click();
      }
    }
  }}
  aria-label="Sign in with Facebook"
  transition="all .25s ease"
  justify="center"
  align="center"
  bg="rgb(19,21,54)"
  borderRadius="15px"
  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
    borderRadius: borderRadius.xl,
    padding: "20px",
    backgroundColor: secondary.focus,
    "&:hover": {
      backgroundColor: secondary.dark,
    },
  })}
  >
  <Icon
  as={FaFacebook}
  w="20px"
  h="20px"
  sx={({ palette: { white } }) => ({
    color: white.focus,
  })}
  />
  <div style={{ display: "none" }}>
  <FacebookLogin
  appId="1030131891262508"
  autoLoad={false}
  fields="name,email"
  callback={handleFacebookResponse}
  onFailure={handleFacebookFailure}
  id="facebookSignInButton"
  />
  </div>
  </IconButton>
  </a>
  </GradientBorder>*/}
  <GradientBorder borderRadius="xl">
  <a href="#">
  <IconButton
  transition="all .25s ease"
  justify="center"
  align="center"
  bg="rgb(19,21,54)"
  borderRadius="15px"
  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
    borderRadius: borderRadius.xl,
    padding: "20px",
    backgroundColor: secondary.focus,
    "&:hover": {
      backgroundColor: rgba(secondary.focus, 0.9),
    },
  })}
  onClick={(event) => {
    event.preventDefault();
    document.getElementById("appleSignInButton").click();
  }}
  aria-label="Sign in with Apple"
  >
  <Icon
  as={FaApple}
  w="20px"
  h="20px"
  sx={({ palette: { white } }) => ({
    color: white.focus,
  })}
  />
  </IconButton>
  </a>
  <AppleSignin
  id="appleSignInButton"
  style={{ display: 'none' }}
  authOptions={{
    clientId: 'digital.tgt.wtf',
    scope: 'email name',
    redirectURI: 'https://join.tgt.wtf/Register',
    state: 'state',
    nonce: 'nonce',
    usePopup: true,
  }}
  onSuccess={handleSuccess}
  onError={handleError}
  />
  </GradientBorder>
  <GradientBorder borderRadius="xl">
  <IconButton
  onClick={handleInitializeAndShowModal}
  transition="all .25s ease"
  justify="center"
  align="center"
  bg="rgb(19,21,54)"
  borderRadius="15px"
  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
    borderRadius: borderRadius.xl,
    padding: "20px",
    paddingTop: "18px",
    paddingLeft: "16px",
    paddingRight: "16px",
    backgroundColor: secondary.focus,
    "&:hover": {
      backgroundColor: rgba(secondary.focus, 0.9),
    },
  })}
  >
  <GoogleOAuthProvider clientId="773639736872-lbp37hffgncmqkaj0h003ufkeinmnr0u.apps.googleusercontent.com">
  <div id="custom-google-login-wrapper">
  <GoogleLogin
  shape="pill"
  logo_alignment="center"
  theme="filled_black"
  size="medium"
  type="icon"
  onSuccess={onSuccess}
  onError={() => {
    console.log('Login Failed');
  }}
  />
  </div>
  </GoogleOAuthProvider>
  </IconButton>
  </GradientBorder>
  <GradientBorder borderRadius="xl">
  <a href="#">
  <IconButton
  onClick={handleInitializeAndShowModal}
  transition="all .25s ease"
  justify="center"
  align="center"
  bg="rgb(19,21,54)"
  borderRadius="15px"
  sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
    borderRadius: borderRadius.xl,
    padding: "20px",
    backgroundColor: secondary.focus,
    "&:hover": {
      backgroundColor: rgba(secondary.focus, 0.9),
    },
  })}
  >
  <Icon
  as={SiNear}
  w="10px"
  h="10px"
  sx={({ palette: { white } }) => ({
    color: white.focus,
  })}
  />
  </IconButton>
  </a>
  </GradientBorder>
  </Stack>

  <VuiTypography
  color="text"
  fontWeight="bold"
  textAlign="center"
  mb="14px"
  sx={({ typography: { size } }) => ({ fontSize: size.lg })}
  >
  </VuiTypography>
  </VuiBox>
  </GradientBorder>

 <Alert severity="info" size="small" style={{ marginTop: "50px" }}>
  If you log in with Meteor wallet, kindly <u onClick={refreshPage} style={{ cursor: "pointer" }}>refresh</u> the page.
</Alert>

  </CoverLayout>
  );
}

export default SignIn;
