import React, { useEffect, useState } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import PageLayout from "examples/LayoutContainers/PageLayout";

function SignIn() {

  return (
    <PageLayout>
    <GradientBorder minWidth="100%" maxWidth="100%">
    <VuiBox
    component="form"
    role="form"
    borderRadius="inherit"
    p="45px"
    sx={({ palette: { secondary } }) => ({
      backgroundColor: secondary.focus,
    })}
    >
    <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
    Mobility Asia’s Personal Data and Privacy Policy
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
    Mobility Asia Sdn. Bhd. ("Mobility Asia") respects your privacy. We view and treat your Personal Data seriously. In view of this, we have developed a Privacy Policy pursuant to our local Personal Data Protection Act 2010 that guides how Mobility Asia collects, processes, uses, discloses, transfers and stores your Personal Data.
    </VuiTypography>
    <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
    Collection and Use of Personal Data
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
    In this Policy, 'Personal Data' refers to information that relates directly or indirectly to you, who is identified or identifiable from that information, or from that and other information.

    During the course of your transactions and dealings with Mobility Asia, you may be requested to furnish your Personal Data from time to time to enable us to enter into transaction with you or to deliver the necessary services and/or our products in connection with our business. Mobility Asia may share your Personal Data with their third party service providers in a consistent manner with this Privacy Policy. Mobility Asia may also combine your Personal Data with other relevant information to provide and improve Mobility Asia's products and services. If you do not provide us with your Personal Data, we may not be able to perform our services for you.
    </VuiTypography>
    <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
    Scope of Personal Data Collection
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
    When you carry out transactions with Mobility Asia, we may collect the following Personal Data from you:

    First Name
    Last Name
    Email
    Phone Number
    Date of Birth
    Gender
    Race
    The list of Personal Data stated above is not exhaustive and may include other relevant Personal Data (depending on the nature of the transaction).
    </VuiTypography>
    <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
    How Mobility Asia Uses Your Personal Data
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
    Your Personal Data that Mobility Asia collects allows us to process your transactions with us in a timely, relevant manner.

    Your Personal Data also allows us to keep you posted on Mobility Asia's latest promotions, announcements and events, as well as assist us in improving our products and services.

    From time to time, Mobility Asia may use your Personal Data to send important notices, emails, letters or short messaging service (SMS) messages – such as communications regarding any marketing and promotional activities of Mobility Asia's, your purchases and policy updates/changes. As such information is crucial in your dealings with Mobility Asia, you are advised not to opt out of receiving such communications.

    Mobility Asia may also use your Personal Data for internal purposes such as auditing, data analysis, and research to improve Mobility Asia's products, services and customer communications.

    Mobility Asia may also share your Personal Data with third party providers that provide services such as information processing, payment processing, managing and enhancing customer data, customer service, and customer research or satisfaction surveys.
    </VuiTypography>
        <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
Protection of Personal Data
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
Mobility Asia will take necessary precautions, both administrative and technical, to safeguard your Personal Data against loss, theft, misuse and unauthorized access, disclosure, usage, alteration or destruction. However you hereby consent and authorize us to provide or disclose Personal Data to any person to whom we are compelled or required to do so under law or in response to a competent regulatory or government agency or any other person reasonably requiring the same in order for us to operate and maintain our business in a consistent manner with this Policy.    </VuiTypography>
        <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
Retention of Personal Data and Access
    </VuiTypography>   
    <VuiTypography
    color="white"
    fontWeight="0"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.sm,
    })}
    >
We at Mobility Asia endeavour to make it easy for you to keep your information accurate, complete and up to date. We will retain your Personal Data for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is permitted or required by law.

You can help ensure that your contact information and preferences are accurate, complete, and up to date by contacting Mobility Asia at support@tgt.wtf where applicable. You may also correct the data if it is inaccurate or out-of-date, or delete the data if Mobility Asia is not required to retain it by law or for legitimate business purposes.

We may decline to process requests that are unreasonably repetitive, require disproportionate technical effort, jeopardise others’ privacy, are extremely impractical, or for which access is not otherwise required by local law.

Mobility Asia reserves the absolute right and discretion to update and amend this Policy from time to time. Any material changes to this Policy will be updated and published on our website.    </VuiTypography>
    </VuiBox>
    </GradientBorder>
    </PageLayout>
    );
}

export default SignIn;
