// Success component
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageLayout from "examples/LayoutContainers/PageLayout";
import CircularProgress from '@mui/material/CircularProgress';
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import lucky from 'assets/images/lucky.png';
import rebirth from 'assets/images/rebirth.png';
import VuiSnackbar from "components/VuiSnackbar";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import voucher2 from 'assets/images/RM10_Onsemiro.png';
import voucher1 from 'assets/images/RM15_Gangnam_Pizza.png';
import voucher3 from 'assets/images/v3.png';
import voucher4 from 'assets/images/v4.png';
import voucher5 from 'assets/images/v5.png';
import voucher6 from 'assets/images/v6.png';
const Success = () => {
  const [customerName, setCustomerName] = useState('');
  const [successSB, setSuccessSB] = useState(false);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const formattedDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  const history = useHistory();
  const cardStyle = {
    borderRadius: '25px',
    color: 'white',
    padding: '20px',
    position: 'relative',
    border: '1px solid white',
  };
  const gpackage = localStorage.getItem('package');
  const gift = localStorage.getItem('gift');
  const series = localStorage.getItem('series');

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows:true
  };
  const cardStyles = {
    borderRadius: '25px',
    color: 'white',
    padding: '20px',
    position: 'relative',
  };

  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id');
        const token = queryParams.get('token');
        const getOrderID = await fetch(`/session2?session_id=${sessionId}&package=${gpackage}&gift=${gift}&series=${series}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!getOrderID.ok) {
          throw new Error('Failed to Keep Session');
        }
        const data = await getOrderID.json();
        fetch('/checkStatus', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        history.push({
          pathname: '/welcome',
        });
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(true);
      }
    };

    fetchCustomerName();
  }, [location.search, history]);

  if (loading) {
    return (
      <CoverLayout
      top="50px"
      title=""
      color="white"
      image={bgImage}
      premotto={"COMMUNITY POWERED COMMERCE"}
      motto={"Togethër"}
      cardContent
      >
      <Grid style={{ marginTop:"50px"}} item xs={12} lg={5} xl={5}>
      {gpackage === "1" || gpackage === "3" ? (
      <Card>
      <div style={cardStyles}>
      <p style={{ fontSize:'12px',marginBottom:'10px'}}>Pre-sales completed. Please await shipment.</p>
      <p style={{fontSize:'16px',marginBottom:'10px'}}>Order Details</p>
        <>
        <img style={{maxWidth:"100%",height:"auto"}} src="https://app.tgt.wtf/loyalty/uploads/package/rebirths.png" alt="package Image" />
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>Product Name: <strong>The Cheek Canvas: Rebirth Blusher</strong></p>
        </>
      <div style={{ color: 'white', paddingTop: '20px' }}>
      <VuiButton style={{background:"#c9fbd5",color:"#01b574"}} variant="contained" color="success"> PURCHASE COMPLETE. PROCESSING ORDER. </VuiButton>
      </div>
      </div>
      </Card>
    ):(
      <Card>
      <div style={cardStyles}>
      <p style={{ fontSize:'12px',marginBottom:'10px'}}>Pre-sales completed. Please await shipment.</p>
      <p style={{fontSize:'16px',marginBottom:'10px'}}>Order Details</p>
        <>
        <img style={{maxWidth:"100%",height:"auto"}} src="https://app.tgt.wtf/loyalty/uploads/package/lucky.png" alt="package Image" />
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>Product Name: <strong>The Cheek Canvas: Lucky Blusher</strong></p>
        </>
      <div style={{ color: 'white', paddingTop: '20px' }}>
      <VuiButton style={{background:"#c9fbd5",color:"#01b574"}} variant="contained" color="success"> PURCHASE COMPLETE. PROCESSING ORDER. </VuiButton>
      </div>
      </div>
      </Card>
    )
  }
      </Grid>
      {gpackage === "1" || gpackage === "2" ? (
      <VuiBox>
      <style>
      {`

      .slick-dots li button:before {
        margin-top:15px;
        font-size: 15px;
        color: white;
        opacity:1;

      }
      .slick-dots li.slick-active button:before {
        color: #f5367b;
      }
      `}
      </style>
      <Slider {...settings} style={{marginTop:"40px"}}>
      <Card style={{ background: 'transparent', padding: '0px' }}>
      <div style={cardStyles}>
      <VuiTypography display="inline" style={{marginBottom:"10px"}} variant="h5" color="white" mb={{ xs: 5, xl: 5, xxl: 5 }}>Voucher</VuiTypography>
      <img style={{maxWidth:"100%",height:"auto",marginTop:"10px"}} src={voucher1} alt="package Image" />
      <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 15 discount voucher at Gangnam Pizza</strong></p>
      <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
      <div style={{ color: 'white', paddingTop: '20px' }}>
      </div>
      </div>
      </Card>

        <Card style={{ background: 'transparent', padding: '0px' }}>
        <div style={cardStyles}>
        <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
        <img style={{maxWidth:"100%",height:"auto"}} src={voucher2} alt="package Image" />
        <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 10 discount voucher at Onsemiro Restaurant</strong></p>
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
        <div style={{ color: 'white', paddingTop: '20px' }}>
        </div>
        </div>
        </Card>

        <Card style={{ background: 'transparent', padding: '0px' }}>
        <div style={cardStyles}>
        <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
        <img style={{maxWidth:"100%",height:"auto"}} src={voucher3} alt="package Image" />
        <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 39 discount Wyndham Grand Bangsar Ramadhan Buffet</strong></p>
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>For reservation, please contact
        <br />Tel: +603 - 2298 1888 (ext. 3400, 3404, 3405)
        <br />WhatsApp/Call : +6016 - 336 6792 | +6016 336 0261
        <br />Email: fb1@wyndhamgrandbangsarkl.com
        </p>
        <div style={{ color: 'white', paddingTop: '20px' }}>
        </div>
        </div>
        </Card>

        <Card style={{ background: 'transparent', padding: '0px' }}>
        <div style={cardStyles}>
        <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
        <img style={{maxWidth:"100%",height:"auto"}} src={voucher4} alt="package Image" />
        <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>RM 100 discount Wyndham Grand Bangsar Staycation Promo</strong></p>
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>For reservation, please contact
        <br />Tel: +603 - 2298 1888 (ext. 3400, 3404, 3405)
        <br />WhatsApp/Call : +6016 - 336 6792 | +6016 336 0261
        <br />Email: fb1@wyndhamgrandbangsarkl.com
        </p>
        <div style={{ color: 'white', paddingTop: '20px' }}>
        </div>
        </div>
        </Card>
        <Card style={{ background: 'transparent', padding: '0px' }}>
        <div style={cardStyles}>
        <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
        <img style={{maxWidth:"100%",height:"auto"}} src={voucher5} alt="package Image" />
        <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>10% Discount at Feeka Coffee Roasters (All Outlets)</strong></p>
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
        <div style={{ color: 'white', paddingTop: '20px' }}>
        </div>
        </div>
        </Card>

        <Card style={{ background: 'transparent', padding: '0px' }}>
        <div style={cardStyles}>
        <p style={{fontSize:'16px',marginBottom:'10px'}}><strong>Voucher</strong></p>
        <img style={{maxWidth:"100%",height:"auto"}} src={voucher6} alt="package Image" />
        <p style={{ fontSize:'15px',marginBottom:'10px',marginTop:'20px'}}><strong>10% Discount at Pizza Mansion (All Outlets)</strong></p>
        <p style={{ fontSize:'13px',marginBottom:'10px'}}>Please show the voucher at the counter during ordering.</p>
        <div style={{ color: 'white', paddingTop: '20px' }}>
        </div>
        </div>
        </Card>
      </Slider>
      </VuiBox>
    ):(
      null
    )
  }
      </CoverLayout>
      );
  }

};

export default Success;
