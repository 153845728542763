import { useState,useEffect } from "react";
import { useHistory,useLocation } from 'react-router-dom';

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PageLayout from "examples/LayoutContainers/PageLayout";
import VuiTypography from "components/VuiTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";

import bgImage from "assets/images/background-cover-auth-signup.png";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import Address from "layouts/pages/users/new-user/components/Address";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
  import { setupWalletSelector } from "@near-wallet-selector/core";
  import { setupModal } from "@near-wallet-selector/modal-ui";
  import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
  import "@near-wallet-selector/modal-ui/styles.css"
  import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
function getSteps() {
  return ["Billing Address"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
  case 0:
    return <Address formData={formData} />;
  default:
    return null;
  }
}

function NewBill() {
    const reselect = async (eve) => {
    history.push("/Plan");
  }
    const logout = async (eve) => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      await myNearWallet.signOut();
      await meteorWallet.signOut();
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      localStorage.clear();
      history.push('/Plan');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
 const token = localStorage.getItem('token');
 const [email, setemail] = useState('');
 const history = useHistory();
 const [activeStep, setActiveStep] = useState(0);
 const steps = getSteps();
 const { formId, formField } = form;
 const currentValidation = validations[activeStep];
 const isLastStep = activeStep === steps.length - 1;
  const [getaddress1, setaddress1] = useState('');
 const [getaddress2, setaddress2] = useState('');
 const [getcity, setcity] = useState('');
 const [getstate, setstate] = useState('');
 const [getzip, setzip] = useState('');
const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
 const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
 const handleBack = () => setActiveStep(activeStep - 1);
 async function fetchData() {
   const spackage = localStorage.getItem('package');
  if(!spackage){
    history.push("/Plan");
  }
    const social = localStorage.getItem('social');
  if(!social){
    history.push("/Plan");
  }
    try {
      const response = await fetch('/search', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setaddress1(data.profile.address1);
      setaddress2(data.profile.address2);
      setcity(data.profile.city);
      setstate(data.profile.region);
      setzip(data.profile.postcode);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }

  }
  useEffect(() => {
    fetchData().then(() => {
      setInitialValuesLoaded(true);
    });
  }, []);

  const paytest = async (eve) => {
         history.push({
       pathname: '/payment_test',
       state: { token: token,email:email},
     });
  }
 const submitForm = async (values, actions) => {
  const { address1, address2, city, states, zip } = values;
  try {
    const response = await fetch('/updateCust', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address1: address1,
        address2: address2,
        city: city,
        state: states,
        postcode: zip
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
     history.push({
       pathname: '/Payment',
       state: { token: token,email:email},
     });
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
  actions.setSubmitting(false);
  actions.resetForm();
  setActiveStep(0);
};

const handleSubmit = (values, actions) => {
  if (isLastStep) {
    submitForm(values, actions);
  } else {
    setActiveStep(activeStep + 1);
    actions.setTouched({});
    actions.setSubmitting(false);
  }
};

return (
  <CoverLayout
  title="/Continue"
  color="white"
  description="Let’s get you activated!"
  image={bgImage}
  premotto={"COMMUNITY POWERED COMMERCE"}
  motto={"Togethër"}
  cardContent
  >
  <VuiBox py={0} mb={20}>
  <Grid container justifyContent="center" sx={{ height: "100%" }}>
  <Grid item xs={12} lg={10}>
  {initialValuesLoaded && (
<Formik
  initialValues={{
    address1: getaddress1,
    address2: getaddress2,
    city: getcity,
    states: getstate,
    zip: getzip,
  }}
  validationSchema={currentValidation}
  onSubmit={handleSubmit}
>
  {({ values, errors, touched, isSubmitting }) => (
    <Form id={formId} autoComplete="off">
    <Card sx={{ height: "100%" }}>
    <VuiBox>
    <VuiBox>
    {getStepContent(activeStep, {
      values,
      touched,
      formField,
      errors,
    })}
    <VuiBox mt={2} width="100%" display="flex" justifyContent="space-between">
    {activeStep === 0 ? (
      <VuiBox />
      ) : (
      <VuiButton
      variant="gradient"
      sx={{ minWidth: "100px" }}
      color="light"
      onClick={handleBack}
      >
      prev
      </VuiButton>
      )}
      <VuiButton
      disabled={isSubmitting}
      type="submit"
      color="info"
      style={{background:"#F5367B"}}
      fullWidth
      >Pay</VuiButton>
      </VuiBox>
      <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "50px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={reselect}
  >
    Change Product
  </VuiButton>
    <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "20px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={logout}
  >
    Log Out
  </VuiButton>
      </VuiBox>
      </VuiBox>
      </Card>
      </Form>
      )}
  </Formik>
  )}
  </Grid>
  </Grid>
  </VuiBox>
  </CoverLayout>
  );
}

export default NewBill;
