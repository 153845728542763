import React, { useState,useEffect } from 'react';
import { useHistory,useLocation } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from "layouts/pages/users/new-user/components/FormField";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import tnc from 'assets/images/Together Terms and Conditions (User).pdf';
import policy from 'assets/images/Together_Privacy Policy (User)_2024.pdf';

  import { setupWalletSelector } from "@near-wallet-selector/core";
  import { setupModal } from "@near-wallet-selector/modal-ui";
  import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
  import "@near-wallet-selector/modal-ui/styles.css"
  import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
  .required('Required')
  .notOneOf([''], 'First Name cannot be empty'),
  password: Yup.string()
  .matches(/^(?=.*[!@#$%^&*()\-_=+{};:,<.>]).*/, 'alphanumeric, with atleast one upper case, one lower case, one number, one special character and minimum of 8 characters.')
  .matches(/^(?=.*[A-Z]).*/, 'alphanumeric, with atleast one upper case, one lower case, one number, one special character and minimum of 8 characters.')
  .min(8, 'alphanumeric, with atleast one upper case, one lower case, one number, one special character and minimum of 8 characters.')
  .required('Password is required'),
  cpassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match.')
  .required('Please confirm your password.'),
  lastName: Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
  .required('Required')
  .notOneOf([''], 'Last Name cannot be empty'),
  referalcode: Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
  .required('Required')
  .notOneOf([''], 'Referral Code cannot be empty')
  .test('check-email', 'Referral Code No Exist', async function(value) {
    const response = await fetch(`/checkReferral?value=${value}`);
    const data = await response.json();
    if (data.status=='success') {
      return "!";
    } else {
      return !data.message;
    }
  })
  ,
  acceptedTnc: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),

  email: Yup.string()
  .email('Invalid email')
  .required('Required')
  .notOneOf([''], 'Email cannot be empty')
});



const SignUpForm = () => {
  //change route
  const history = useHistory();

  //social email, lastname, firstname
  const [getout, setout] = useState(false);
  const [getcust, setcust] = useState('');
  const [stype, setstype] = useState('');
  const [stoken, setstoken] = useState('');
  const [email, setemail] = useState('');
  const [sfname, setsfname] = useState('');
  const [slname, setslname] = useState('');
  const [walletname, setwalletname] = useState('');
  const [walletType, setwalletType] = useState('');
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
  const [showpassword, setPassword] = useState(false);
  const [acceptedTnc, setAcceptedTnc] = useState(false);
  const [eeremail, seteeremail] = useState(false);
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  async function fetchData() {
  const token = localStorage.getItem('token');
  const spackage = localStorage.getItem('package');
  if(!spackage){
    history.push("/Plan");
  }
  const social = localStorage.getItem('social');
  if(!social){
    history.push("/Plan");
  }
  setcust(token);
  if(token!=null){
    setout(true);
    try {
      const response = await fetch('/search', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      setemail(data.profile.email);
      setsfname(data.profile.fname);
      setslname(data.profile.lname);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }else{
    if (location.state) {
      if (location.state.stype) {
        setstype(location.state.stype);
      }
      if (location.state.stoken) {
        setstoken(location.state.stoken);
      }
      if (location.state.semail) {
        setemail(location.state.semail);
      }
      if (location.state.sfname) {
        setsfname(location.state.sfname);
      }
      if (location.state.slname) {
        setslname(location.state.slname);
      }
      if (location.state.walletType) {
        setwalletType(location.state.walletType);
      }
      if (location.state.walletname) {
        setwalletname(location.state.walletname);
      }
      if (location.state.password) {
        setPassword(location.state.password);
      }
    }
  }
  }
  useEffect(() => {
    fetchData().then(() => {
      setInitialValuesLoaded(true);
    });
  }, []);
  const handleCheckboxChange = (event) => {
     setAcceptedTnc(event.target.checked);
   };
  //form submit
  const handleSubmit = async (values, errors, { setSubmitting, setTouched }) => {
    if(showpassword){
      setTouched({
        firstName: true,
        lastName: true,
        referalcode: true,
        email: true,
        password:true,
        cpassword:true
      });
    }else{
      setTouched({
        firstName: true,
        lastName: true,
        referalcode: true,
        email: true,
        acceptedTnc:true
      });
    }

    if (
      (values.firstName.length === 0 || errors.firstName) ||
      (values.lastName.length === 0 || errors.lastName) ||
      (values.referalcode.length === 0 || errors.referalcode) ||
      (values.email.length === 0 || errors.email) ||
      (values.password.length === 0 || errors.password)||
      (acceptedTnc === false)||
      (values.cpassword.length === 0 || errors.cpassword)
      ) {
      return;
  } else {
    const gpackage = localStorage.getItem('package');

try {
  const walletName = walletname || `${values.firstName}${Math.floor(Math.random() * 10000)}`.toLowerCase();
  
  setLoading(true);
   const checkemail = await fetch(`/checkEmail?value=${values.email}`);
    const checkemaildata = await checkemail.json();
    if (checkemaildata.status=='fail') {
      setLoading(false);
      seteeremail(true);
      return "!";
    } else {
      seteeremail(false);
    }
  const response = await fetch('/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: values.email,
      fname: values.firstName,
      lname: values.lastName,
      referral_code: values.referalcode,
      walletname: walletName,
      walletType: walletType,
      socialmediatype: stype,
      socialmediatoken: stoken,
      package_id: gpackage,
      pass: values.password
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to Register');
  }

  const responseData = await response.json();
  
  if (responseData.status === 'fail') {
    throw new Error(responseData.message);
  }

  localStorage.setItem('token', responseData.token);

  history.push({
    pathname: '/More_info',
    state: { email: values.email },
  });

} catch (error) {
  console.error('Error registering:', error.message);
}

  }
};

  //form submit
  const handleUpdate = async (values, errors, { setSubmitting, setTouched }) => {
         setTouched({
        firstName: true,
        lastName: true,
        email: true,
      });
    if (
      (values.firstName.length === 0 || errors.firstName) ||
      (values.lastName.length === 0 || errors.lastName) ||
      (values.email.length === 0 || errors.email)
      ) {
      return;
  } else {
   try {
    const response = await fetch('/updateName', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getcust}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
      email: values.email,
      fname: values.firstName,
      lname: values.lastName
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    history.push({
    pathname: '/More_info',
    state: { email: values.email },
  });
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }

  }
};
  const reselect = async (eve) => {
    history.push("/Plan");
  }
  const logout = async (eve) => {
    try {
      const selector = await setupWalletSelector({
        network: "mainnet",
        modules: [setupMyNearWallet(), setupMeteorWallet()],
      });
      const myNearWallet = await selector.wallet("my-near-wallet");
      const meteorWallet = await selector.wallet("meteor-wallet");
      await myNearWallet.signOut();
      await meteorWallet.signOut();
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        const cookieName = cookieParts[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
      localStorage.clear();
      setout(false);
      history.push('/Plan');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

return (
  <div>
  {initialValuesLoaded && (
    <>
{getcust === null ? (

    <Formik
    initialValues={{
      firstName: sfname,
      lastName: slname,
      referalcode: "TYRA",
      email: email,
      password: "Aaghjki3w24!",
      cpassword: "Aaghjki3w24!",
      acceptedTnc: false,
    }}
    validationSchema={SignupSchema}
    >
    {({ errors, touched, values,setTouched }) => (
      <Form style={{minWidth:"270px"}}>
      <FormField
      label="First Name"
      name="firstName"
      type="text"
      error={values.firstName.length <= 0 && errors.firstName && touched.firstName}
      success={values.firstName.length > 0 && !errors.firstName}
      />
      <FormField
      label="Last Name"
      name="lastName"
      type="text"
      error={errors.lastName && touched.lastName}
      success={values.lastName.length > 0 && !errors.lastName}
      />
      <FormField
      label="Email"
      name="email"
      type="email"
      error={errors.email && touched.email}
      success={values.email.length > 0 && !errors.email}
      />
            {eeremail === true && (
            <div className="error" style={{ color: "red", fontSize: "10px" }}>This email address already exists</div>
          )}
                  <div className="error" style={{ color: "yellow", fontSize: "10px" }}>Note : Kindly use your direct email to receive order confirmations. Private Relays and other redirection emails are not advisable.</div>

      {showpassword && (
        <>
        <FormField
        label="Password"
        name="password"
        type="password"
        error={errors.password && touched.password}
        success={values.password.length > 0 && !errors.password}
        />
        <FormField
        label="Confirm Password"
        name="cpassword"
        type="password"
        error={errors.cpassword && touched.cpassword}
        success={values.cpassword.length > 0 && !errors.cpassword}
        />
        </>
        )}
      <FormField
      label="Referral Code"
      name="referalcode"
      type="referalcode"
      error={errors.referalcode && touched.referalcode}
      success={values.referalcode.length > 0 && !errors.referalcode}
      />
      <div style={{display:"flex",marginLeft:"10px"}}>
            <FormControlLabel style={{display:"flex",marginBottom:"12px"}}
              control={<Checkbox checked={acceptedTnc} onChange={handleCheckboxChange} style={{ color: "#F5367B" }} />}
              label={
                <span style={{fontSize:"12px"}}>
                  I accept the <a href={tnc} target="_blank" rel="noopener noreferrer" style={{ color: "#F5367B", textDecoration: "underline", marginRight: "4px" }}>Terms & Conditions</a>
                   and <a href={policy} style={{ color: "#F5367B", textDecoration: "underline", marginLeft: "4px" }} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </span>
              }
            />
          </div>
          {acceptedTnc === false && (
            <div className="error" style={{ color: "red", fontSize: "10px" }}>You must accept the Terms & Conditions and Privacy Policy</div>
          )}
      <VuiBox mt={4} mb={1}>
      {
        loading === true ? (
          <VuiButton
            style={{background:"#F5367B"}}
            color="info"
            fullWidth>
            SIGN UP
            <CircularProgress style={{ marginLeft:"10px", width: '20px', height: '20px', color: '#FFFFFF' }} />
          </VuiButton>
        ) : (
          <VuiButton
            style={{background:"#F5367B"}}
            color="info"
            fullWidth
            onClick={() => handleSubmit(values, errors, { setTouched })}
          >
            SIGN UP
          </VuiButton>
        )
      }

      </VuiBox>
      </Form>
      )}
    </Formik>
    ):(
    <Formik
    initialValues={{
      firstName: sfname,
      lastName: slname,
      referalcode: "TYRA",
      email: email,
      password: "Aaghjki3w24!",
      cpassword: "Aaghjki3w24!",
      acceptedTnc: true,
    }}
    validationSchema={SignupSchema}
    >
    {({ errors, touched, values,setTouched }) => (
      <Form style={{minWidth:"270px"}}>
      <FormField
      label="First Name"
      name="firstName"
      type="text"
      error={values.firstName.length <= 0 && errors.firstName && touched.firstName}
      success={values.firstName.length > 0 && !errors.firstName}
      />
      <FormField
      label="Last Name"
      name="lastName"
      type="text"
      error={errors.lastName && touched.lastName}
      success={values.lastName.length > 0 && !errors.lastName}
      />
      <FormField
      label="Email"
      name="email"
      type="email"
      error={errors.email && touched.email}
      success={values.email.length > 0 && !errors.email}
      />
      <VuiBox mt={4} mb={1}>
      {
        loading === true ? (
          <VuiButton
            style={{background:"#F5367B"}}
            color="info"
            fullWidth>
            Continue
            <CircularProgress style={{ marginLeft:"10px", width: '20px', height: '20px', color: '#FFFFFF' }} />
          </VuiButton>
        ) : (
          <VuiButton
            style={{background:"#F5367B"}}
            color="info"
            fullWidth
            onClick={() => handleUpdate(values, errors, { setTouched })}
          >Continue</VuiButton>
        )
      }

      </VuiBox>
      </Form>
      )}
    </Formik>
    )}
    </>
    )}
{getout === true ? (
  <>
  <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "100px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={reselect}
  >
    Change Product
  </VuiButton>
    <VuiButton
    style={{ border: "2px solid #f5367b", color: "#f5367b", background: "transparent", marginTop: "20px" }}
    variant="contained"
    color="error"
    fullWidth
    onClick={logout}
  >
    Log Out
  </VuiButton>
  </>
) : (
  null
)}
  </div>
  );
};

export default SignUpForm;
