
export default {
  formId: "new-user-form",
  formField: {
    address1: {
      name: "address1",
      label: "address 1",
      type: "text",
      placeholder: "eg. Street 111",
      errorMsg: "Address is required.",
    },
    address2: {
      name: "address2",
      label: "address 2",
      type: "text",
      placeholder: "eg. Street 221",
    },
    city: {
      name: "city",
      label: "city",
      type: "text",
      placeholder: "eg. Petaling Jaya",
      errorMsg: "City is required.",
    },    
    states: {
      name: "states",
      label: "states",
      type: "text",
      placeholder: "eg. Selangor",
      errorMsg: "State is required.",
    },
    zip: {
      name: "zip",
      label: "zip",
      type: "number",
      placeholder: "Key in your postcode",
      errorMsg: "Zip is required.",
      invalidMsg: "Zipcode is not valie (e.g. 40000).",
    },
  },
};
