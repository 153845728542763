import { useState,useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useHistory, useLocation } from 'react-router-dom';

// Pricing page components
import Header from "layouts/pages/pricing-page2/components/Header";
import Footer from "layouts/pages/pricing-page2/components/Footer";
import PricingCards from "layouts/pages/pricing-page2/components/PricingCards";
import TrustedBrands from "layouts/pages/pricing-page2/components/TrustedBrands";
import Faq from "layouts/pages/pricing-page2/components/Faq";
import tgtImage from 'assets/images/tgt.svg';
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/background-cover-auth-signup.png";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

function PricingPage() {
  const [gpackage, setpackage] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const { token,email } = location.state;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows:true
  };

  useEffect(() => {
    fetchPackageData();
  }, []);

  const fetchPackageData = async () => {
    try {
      const response = await fetch("/package");
      if (!response.ok) {
        throw new Error("Failed to fetch package data");
      }
      const data = await response.json();

      setpackage(data);

    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };
  return (
    <CoverLayout
    title=""
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >

    <VuiBox
    borderRadius="inherit"
    sx={({ palette: { secondary } }) => ({
      backgroundColor: secondary.focus,
    })}
    >
    <Slider {...settings}>
    {gpackage.map((item, index) => (

      <DefaultPricingCard
      badge={{ color: "dark",img:item.img, label: item.name,description:item.description }}
      price={{ currency: "", value: item.price }}
      specifications={item.features.map((feature, index) => ({
        label: feature.title,
        includes: feature.subprice,
      }))}
      action={{
        type: "new",
        id: item.id,
        color: item.stripe_key,
        gift: item.gift_id,
        series: item.series,
        route: token,
        label: email,
      }}
      />
      ))}
    </Slider>
    </VuiBox>
    </CoverLayout>

    );
}

export default PricingPage;
