import { useState } from "react";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FaUserFriends, FaFacebook, FaGoogle } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
import { CgProfile } from "react-icons/cg";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinVerticalFill } from "react-icons/pi";

import radialGradient from "assets/theme/functions/radialGradient";
import rgba from "assets/theme/functions/rgba";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background-cover-auth-signup.png";

function SignIn() {
  const [earnings, setEarnings] = useState(0);
  const [tapCount, setTapCount] = useState(0);

  const handleTap = (event) => {
    event.preventDefault();
    const earningsPerTap = 1; 
    const newEarnings = earnings + earningsPerTap;
    setEarnings(newEarnings);
    setTapCount(tapCount + 1);
  };
  return (
    <CoverLayout
    top="40px"
    title=""
    color="white"
    description="Let’s get you activated!"
    image={bgImage}
    premotto={"COMMUNITY POWERED COMMERCE"}
    motto={"Togethër"}
    cardContent
    >

    <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
    <VuiBox
    component="form"
    role="form"
    borderRadius="inherit"
    p="30px"
    sx={({ palette: { secondary } }) => ({
      backgroundColor: secondary.focus,
    })}
    >
    <div>
    <VuiTypography
    color="white"
    fontWeight="bold"
    textAlign="center"
    mb="24px"
    sx={({ typography: { size } }) => ({
      fontSize: size.lg,
    })}
    >
    Tap To Earn
    </VuiTypography>
    <div style={{marginBottom:"20px"}}>
    <VuiTypography variant="lg" color="white" fontWeight="bold">Earnings: ${earnings}</VuiTypography>
    </div>
    <Stack mt="50px" mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
    <GradientBorder borderRadius="xl">
    <a onClick={handleTap}>
    <IconButton
    transition="all .25s ease"
    justify="center"
    align="center"
    bg="rgb(19,21,54)"
    borderRadius="15px"
    sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
      borderRadius: borderRadius.xl,
      padding: "20px",
      backgroundColor: secondary.focus,
      "&:hover": {
        backgroundColor: secondary.dark,
      },
    })}
    >
    <Icon
    style={{width:"100px",height:"100px"}}
    as={PiCoinVerticalFill}
    sx={({ palette: { white } }) => ({
      color: white.focus,
    })}
    />
    </IconButton>
    </a>
    </GradientBorder>
    </Stack>
    </div>

    <Stack mt="50px" mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
    <GradientBorder borderRadius="xl">
    <a href="#">
    <IconButton
    transition="all .25s ease"
    justify="center"
    align="center"
    bg="rgb(19,21,54)"
    borderRadius="15px"
    sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
      borderRadius: borderRadius.xl,
      padding: "20px",
      backgroundColor: secondary.focus,
      "&:hover": {
        backgroundColor: secondary.dark,
      },
    })}
    >
    <Icon
    as={GiThreeFriends}
    w="20px"
    h="20px"
    sx={({ palette: { white } }) => ({
      color: white.focus,
    })}
    />
    </IconButton>
    </a>
    </GradientBorder>
    <GradientBorder borderRadius="xl">
    <a href="#">
    <IconButton
    transition="all .25s ease"
    justify="center"
    align="center"
    bg="rgb(19,21,54)"
    borderRadius="15px"
    sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
      borderRadius: borderRadius.xl,
      padding: "20px",
      backgroundColor: secondary.focus,
      "&:hover": {
        backgroundColor: secondary.dark,
      },
    })}
    >
    <Icon
    as={FaRankingStar }
    w="20px"
    h="20px"
    sx={({ palette: { white } }) => ({
      color: white.focus,
    })}
    />
    </IconButton>
    </a>
    </GradientBorder>
    <GradientBorder borderRadius="xl">
    <a href="#">
    <IconButton
    transition="all .25s ease"
    justify="center"
    align="center"
    bg="rgb(19,21,54)"
    borderRadius="15px"
    sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
      borderRadius: borderRadius.xl,
      padding: "20px",
      backgroundColor: secondary.focus,
      "&:hover": {
        backgroundColor: secondary.dark,
      },
    })}
    >
    <Icon
    as={CgProfile}
    w="20px"
    h="20px"
    sx={({ palette: { white } }) => ({
      color: white.focus,
    })}
    />
    </IconButton>
    </a>
    </GradientBorder>
    </Stack>

    </VuiBox>
    </GradientBorder>
    </CoverLayout>
    );
}

export default SignIn;
