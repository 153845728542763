import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";
import { useHistory, useLocation } from 'react-router-dom';

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

function DefaultPricingCard({ badge, price, specifications, action }) {
  const history = useHistory();
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <VuiBox key={label} display="flex" alignItems="center" p={1}>
    <VuiBox display="flex" alignItems="center" justifyContent="space-between" flex="1">
    <VuiTypography variant="body2" color="text">
    {label}
    </VuiTypography>
    <VuiTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
    {includes}
    </VuiTypography>
    </VuiBox>
    </VuiBox>
    ));

  return (
    <Card style={{background:"#131537"}}>
    <img style={{maxWidth:"100%",height:"auto"}} src={badge.img} alt="Logo" />
    <VuiBox textAlign="center">
    <VuiBadge
    style={{marginTop:"15px"}}
    variant="basic"
    color={badge.color}
    size="lg"
    badgeContent={badge.label}
    circular
    container
    />
    <p style={{color:"white",fontSize:"13px"}}>*excluding RM 10 flat rate nationwide shipping fee</p>
    <VuiBox my={1}>
    <VuiTypography variant="h4" color="white">
    <VuiTypography display="inline" variant="h4" color="white">
    {price.currency}
    </VuiTypography>
    {price.value}
    </VuiTypography>
    <VuiTypography style={{fontSize:"12px",background:"#f5367b",borderRadius:"20px",padding:"10px",margin:"10px",display:"block"}} display="inline" variant="p" color="white">
    {badge.description}
    </VuiTypography>
    </VuiBox>
    </VuiBox>
    <VuiBox>
    {renderSpecifications}

    {action.type === "internal" ? (
      <VuiBox mt={3}>
      <VuiButton
      style={{background:"#F5367B"}}
      onClick={() => {
        localStorage.setItem('package', action.route);
        localStorage.setItem('gift', action.label);
        localStorage.setItem('item', action.color);
        localStorage.setItem('series', action.series);
        history.push({
          pathname: '/Register',
        });
      }}
      color="info"
      fullWidth>
      Pre-Order Now&nbsp;
      </VuiButton>
      </VuiBox>
      ) : (
      <VuiBox mt={3}>
      <VuiButton
      onClick={() => {
        localStorage.setItem('package', action.route);
        localStorage.setItem('gift', action.label);
        localStorage.setItem('item', action.color);
        localStorage.setItem('series', action.series);
        history.push({
          pathname: '/Continue',
        });
      }}
      style={{background:"#F5367B",marginTop:"30px"}}
      color="info"
      fullWidth
      >
      Pre-Order Now&nbsp;
      </VuiButton>

      </VuiBox>
      )}
      </VuiBox>
      </Card>
      );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "text",
      "warning",
      "error",
      "light",
      "dark",
      ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
